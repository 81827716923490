export const MetricsMap = {
    CUSTOM_IMPRESSIONS: {
        value: 'custom_impressions',
        label: 'Impressions',
    },
    CUSTOM_CLICKS: {
        value: 'custom_clicks',
        label: 'Clicks',
    },
    CUSTOM_INSTALLS: {
        value: 'custom_installs',
        label: 'Installs',
    },
    COST: {
        value: 'adn_cost',
        label: 'Cost',
    },
    IPM: {
        value: 'ipm',
        label: 'IPM',
    },
    ECPM: {
        value: 'ecpm',
        label: 'eCPM',
    },
    ECPI: {
        value: 'ecpi',
        label: 'eCPI',
    },
    CTR: {
        value: 'ctr',
        label: 'CTR',
    },
    CVR: {
        value: 'cvr',
        label: 'CVR',
    },
};

export const CohortMetricsMap = {
    TOTAL_ROI_1D: {
        value: 'total_roi_1d',
        label: 'ROI 1d',
    },
    TOTAL_ROI_7D: {
        value: 'total_roi_7d',
        label: 'ROI 7d',
    },
};

export const getCohortMetricAndPeriod = metric => {
    const metricParts = metric.split('_');
    const cohortPeriod = metricParts[metricParts.length - 1];
    const cohortMetric = metricParts.slice(0, metricParts.length - 1).join('_');

    return [cohortMetric, cohortPeriod];
};

export const [CREATIVES_COHORT_METRICS_OPTIONS, CREATIVES_COHORT_PERIODS_OPTIONS] = Object.values(
    CohortMetricsMap
).reduce(
    (acc, { value }) => {
        const [cohortMetric, cohortPeriod] = getCohortMetricAndPeriod(value);
        const allCohortMetrics = acc[0];
        const allCohortPeriods = acc[1];
        if (!allCohortMetrics.includes(cohortMetric)) allCohortMetrics.push(cohortMetric);
        if (!allCohortPeriods.includes(cohortPeriod)) allCohortPeriods.push(cohortPeriod);
        return acc;
    },
    [[], []]
);

export const CREATIVES_CORE_METRICS_OPTIONS = Object.values(MetricsMap).map(({ value }) => value);

export const getAvailableMetricsOptions = (allUserMetrics, allRequestedMetrics) =>
    Array.from(new Set(allRequestedMetrics)).filter(metric => allUserMetrics.find(userMetric => userMetric === metric));

const getSortedMetrics = metrics => {
    const sortCallback = (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());

    if (Array.prototype.toSorted) {
        return metrics.toSorted(sortCallback);
    } else {
        return [...metrics].sort(sortCallback);
    }
};

export const ALL_METRICS_OPTIONS = [...Object.values(MetricsMap), ...Object.values(CohortMetricsMap)];
export const SORTED_METRICS_OPTIONS = getSortedMetrics(ALL_METRICS_OPTIONS);
